import styled from 'styled-components';

export const Container = styled.header`
  grid-area: header;
  display: grid;
  grid-template-columns: min-content auto 1fr;
  grid-template-rows: min-content;
  grid-template-areas: 'logo . navbar';
  background-color: transparent;
  padding: 16px 32px;

  a.logo-wrapper {
    grid-area: logo;
    align-self: center;
  }

  a.logo-icon-wrapper {
    grid-area: logo;
    display: none;
    align-self: center;
  }

  img.logo {
    margin-left: 16px;
    align-self: center;
    max-width: 150px;
  }

  img.logo-icon {
    align-self: center;
  }

  @media screen and (max-width: 475px) {
    img.logo {
      align-self: center;
      width: 100%;
    }

    img.logo-icon {
      align-self: center;
      max-width: 50px;
    }

    a.logo-wrapper {
      grid-area: logo;
      display: none;
    }

    a.logo-icon-wrapper {
      grid-area: logo;
      display: block;
    }
  }
`;

export const Navbar = styled.nav`
  grid-area: navbar;
  align-self: center;

  ul {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    list-style: none;

    li {
      color: ${props => props.theme.colors.darkBlue};
      font-weight: bold;
      margin-right: 3%;

      a {
        text-decoration: none;
        margin-left: 1em;
      }
    }
  }
`;
