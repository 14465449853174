import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    outline: 0;
  }
  html, body, #root {
    height: 100%;
  }
  body {
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
    background: #fefeff;
    color: #424242;
    font-family: 'Montserrat', sans-serif;
  }
  input, button {
  }
  button {
    cursor: pointer;
  }

  h1, h2, h3 {
    color: ${props => props.theme.colors.darkBlue};
  }

  h1 {
    font-size: 1.5rem;
  }
`;

export default GlobalStyles;
