import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 16px 1fr 16px;
  grid-template-rows: minmax(100px, 300px) auto auto;
  gap: 8px 16px;
  grid-template-areas:
    'intro-image intro-image .'
    '. intro-title .'
    '. intro-description .'
    '. how-to-separator .'
    '. how-to-title .'
    '. how-to-info .';

  @media screen and (min-width: 690px) {
    grid-template-columns: 32px repeat(4, 1fr) 32px;
    grid-template-rows: min-content min-content min-content min-content 16px max-content 16px max-content;
    gap: 8px 16px;
    grid-template-areas:
      'intro-image intro-image . . . .'
      'intro-image intro-image intro-title intro-title intro-title .'
      'intro-image intro-image intro-description intro-description intro-description .'
      'intro-image intro-image . . . .'
      '. . . . . .'
      '. . how-to-separator how-to-separator . .'
      '. . . . . .'
      '. how-to-title how-to-title how-to-title how-to-title .'
      '. how-to-info how-to-info how-to-info how-to-info .';
  }
`;
