import React, { Fragment } from 'react';
import {
  faEnvelope,
  faFileSignature,
  faFileDownload,
  faDollarSign
} from '@fortawesome/free-solid-svg-icons';

import { Container, SectionSeparator, Title } from './styles';
import Step from './components/Step';

const FleetOwnerHowTo = () => (
  <Fragment>
    <SectionSeparator />
    <Title>Entenda como fazer</Title>
    <Container>
      <Step
        icon={faFileDownload}
        title="1. Baixe o requerimento "
        description={`Faça o download do formulário de requerimento para cadastramento como frotista da BHTrans`}
        link={
          'https://prefeitura.pbh.gov.br/sites/default/files/estrutura-de-governo/bhtrans/2018/documentos/Requerimento%20para%20Frotistas%20.pdf'
        }
      />
      <Step
        icon={faFileSignature}
        title="2. Preencha e assine o requerimento "
        description="Faça a impressão do documento, preencha e assine."
      />
      <Step
        icon={faEnvelope}
        title="3. Envie para a BHTrans "
        description="Envie o documento preenchido e assinado, acompanhado da cópia do Certificado de Registro e Licenciamento de Veículos (CRLV) atualizado de todos os veículos que deseja cadastrar para o e-mail: frotista.rotativo@pbh.gov.br ou entregue pessoalmente na GELUR / BHTRANS: Avenida Nossa Senhora de Fátima, 1700 - Carlos Prates, CEP 30710-020."
      />
      <Step
        icon={faDollarSign}
        title="4. Aguarde a aprovação da documentação "
        description="A BHTRANS avaliará a documentação recebida e enviará uma resposta por e-mail. Após a aprovação você já poderá ativar rotativo e/ou bônus para todas as placas cadastradas sem limite de ativação."
      />
    </Container>
  </Fragment>
);

export default FleetOwnerHowTo;
