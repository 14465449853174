import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faQuoteLeft,
  faLongArrowAltLeft,
  faLongArrowAltRight
} from '@fortawesome/free-solid-svg-icons';
import { reviews } from './data';

import {
  Container,
  SectionSeparator,
  SectionTitle,
  Content,
  Title,
  QuotationMarkContainer,
  Description,
  Author,
  CurrentAvatar,
  TopLeftAvatar,
  TopRightAvatar,
  LeftTopAvatar,
  LeftBottomAvatar,
  BottomLeftAvatar,
  BottomRightAvatar,
  AuthorOccupation,
  LeftArrowContainer,
  RightArrowContainer
} from './styles';

import igorAvatar from '../../../../assets/images/userReviews/igor-camargo-alves.png';
import sandreleAvatar from '../../../../assets/images/userReviews/sandrele-reis.jpg';
import lipeehAvatar from '../../../../assets/images/userReviews/lipeeh_assis.jpg';
import samuelAvatar from '../../../../assets/images/userReviews/samuel-pontes.png';
import anaAvatar from '../../../../assets/images/userReviews/ana-luiza-machado-silveira.jpg';
import celsoAvatar from '../../../../assets/images/userReviews/celso-henrique-estolano-gomes.jpg';

const Reviews = () => {
  const [userReviews] = useState(reviews);
  const [currentSelectedIndex, setCurrentSelectedIndex] = useState(0);

  const selectNextReview = () => {
    currentSelectedIndex + 1 > userReviews.length - 1
      ? setCurrentSelectedIndex(0)
      : setCurrentSelectedIndex(currentSelectedIndex + 1);
  };

  const selectPreviousReview = () => {
    currentSelectedIndex - 1 < 0
      ? setCurrentSelectedIndex(userReviews.length - 1)
      : setCurrentSelectedIndex(currentSelectedIndex - 1);
  };

  return (
    <Container>
      <SectionSeparator />
      <SectionTitle>Avaliações</SectionTitle>
      <Content>
        <CurrentAvatar avatarImage={userReviews[currentSelectedIndex].avatar} />
        <TopLeftAvatar avatarImage={igorAvatar} />
        <TopRightAvatar avatarImage={sandreleAvatar} />
        <LeftTopAvatar avatarImage={lipeehAvatar} />
        <LeftBottomAvatar avatarImage={samuelAvatar} />
        <BottomLeftAvatar avatarImage={anaAvatar} />
        <BottomRightAvatar avatarImage={celsoAvatar} />
        <Title>O que nossos usuários já disseram</Title>
        <QuotationMarkContainer>
          <FontAwesomeIcon icon={faQuoteLeft} size="3x" />
        </QuotationMarkContainer>
        <Description>{userReviews[currentSelectedIndex].comment}</Description>
        <Author>{userReviews[currentSelectedIndex].author}</Author>
        <AuthorOccupation>
          {userReviews[currentSelectedIndex].authorOccupation}
        </AuthorOccupation>
        <LeftArrowContainer onClick={selectPreviousReview}>
          <FontAwesomeIcon icon={faLongArrowAltLeft} size="2x" />
        </LeftArrowContainer>
        <RightArrowContainer onClick={selectNextReview}>
          <FontAwesomeIcon icon={faLongArrowAltRight} size="2x" />
        </RightArrowContainer>
      </Content>
    </Container>
  );
};

export default Reviews;
