import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Container } from './styles';

const Feature = ({ icon, title, description, ...rest }) => (
  <Container {...rest}>
    <div className="feature-icon">
      <FontAwesomeIcon icon={icon} size="3x" />
    </div>
    <h2 className="feature-title">{title}</h2>
    <p className="feature-description">{description}</p>
  </Container>
);

export default Feature;
