import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 60px 1fr;
  grid-template-areas:
    'step-icon step-title'
    'step-icon step-description';
  grid-column-gap: 16px;

  div.step-icon {
    grid-area: step-icon;
    align-self: center;
    justify-self: center;

    svg * {
      fill: url(#lgrad);
    }
  }

  h4.step-title {
    font-size: 1rem;
    grid-area: step-title;
    color: ${props => props.theme.colors.darkBlue};
  }

  p.step-description {
    font-size: 0.8rem;
    grid-area: step-description;
  }
`;
