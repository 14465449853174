import styled from 'styled-components';
import LineSeparator from '../../../../components/LineSeparator';

export const Container = styled.div`
  grid-area: presentational-video-section;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: max-content auto;
  grid-row-gap: 32px;
  grid-template-areas:
    '. presentational-video-separator .'
    '. presentational-video-title .'
    'presentational-video-media presentational-video-media presentational-video-media';
  padding: 0 16px;

  h1.presentational-video-title {
    grid-area: presentational-video-title;
    text-align: center;
  }

  div.presentational-video-media {
    grid-area: presentational-video-media;
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    justify-self: center;

    .video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
`;

export const SectionSeparator = styled(LineSeparator)`
  grid-area: presentational-video-separator;
  justify-self: center;
`;
