import igorAvatar from '../../../../assets/images/userReviews/igor-camargo-alves.png';
import sandreleAvatar from '../../../../assets/images/userReviews/sandrele-reis.jpg';
import lipeehAvatar from '../../../../assets/images/userReviews/lipeeh_assis.jpg';
import samuelAvatar from '../../../../assets/images/userReviews/samuel-pontes.png';
import anaAvatar from '../../../../assets/images/userReviews/ana-luiza-machado-silveira.jpg';
import celsoAvatar from '../../../../assets/images/userReviews/celso-henrique-estolano-gomes.jpg';

export const reviews = [
  {
    avatar: sandreleAvatar,
    author: 'Sandrele Reis',
    authorOccupation: '',
    comment: `Achei super prático, intuitivo, bem elaborado! Adorei"`
  },
  {
    avatar: igorAvatar,
    author: 'Igor Camargo Alves',
    authorOccupation: '',
    comment: `Muito bom! Você estaciona e pronto, tudo pelo celular, paga direto no cartão de crédito. Faço questão de avaliar pois realmente funciona e é prático!"`
  },
  {
    avatar: lipeehAvatar,
    author: 'Lipeeh_Assis',
    authorOccupation: '',
    comment: `Excelente aplicativo. Simples de usar, prático para estacionar e mais barato que comprar o rotativo na rua. Recomendo pela comodidade. Parabéns ao desenvolvedores, muito útil o aplicativo. A gente pode comprar o rotativo que o saldo cai na hora. Excelente"`
  },
  {
    avatar: samuelAvatar,
    author: 'Samuel Pontes',
    authorOccupation: '',
    comment: `Excelente! Facilitou muito a utilização do rotativo. Tanto para adquirir que no caso é colocar crédito, quanto a utilização em qualquer local! App simples e funcional. Excelente modernização!"`
  },
  {
    avatar: anaAvatar,
    author: 'Ana Luíza Machado Silveira',
    authorOccupation: '',
    comment: `App super prático e eficiente, muito melhor do que ficar procurando rotativo de papel nas ruas!!"`
  },
  {
    avatar: celsoAvatar,
    author: 'Celso Henrique Estolano Gomes',
    authorOccupation: '',
    comment: `Fácil e tranquilo de se utilizar."`
  }
];
