import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Container } from './styles';
const Step = ({ icon, title, description }) => (
  <Container>
    <div className="step-icon">
      <FontAwesomeIcon icon={icon} size="3x" />
    </div>
    <h4 className="step-title">{title}</h4>
    <p className="step-description">{description}</p>
  </Container>
);

export default Step;
