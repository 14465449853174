import React from 'react';
import { Link } from 'react-router-dom';

import {
  Container,
  LogoImage,
  TitleContact,
  ContactContainer,
  TitleQuickLinks,
  QuickLinksContainer,
  FooterSeparator
} from './styles';
import Logo from '../../assets/images/logo.png';

const Footer = () => (
  <Container>
    <LogoImage src={Logo} width="50%" />
    <div className="footer-description">
      <p className="footer-description-text">
        O app preferido em Belo Horizonte.
      </p>
    </div>
    <TitleContact>Contato</TitleContact>
    <ContactContainer>
      <p className="contact-info">
        <span className="contact-info-title">E-mail: </span>
        <span className="contact-info-description">
          contato@faixaazuldigitalbh.com
        </span>
      </p>
      <p className="contact-info">
        <span className="contact-info-title">Telefone: </span>
        <span className="contact-info-description">(31) 3958 6013</span>
      </p>
    </ContactContainer>
    <TitleQuickLinks>Links rápidos</TitleQuickLinks>
    <QuickLinksContainer>
      <Link to="/">Início</Link>
      <a href="https://faixaazuldigitalbh.com/portal">Portal Web</a>
    </QuickLinksContainer>
    <FooterSeparator />
    <div className="footer-copyright">
      © {new Date().getFullYear()} Inova Soluções em Tecnologia e Gestão.
    </div>
  </Container>
);

export default Footer;
