import React from 'react';
import YouTube from 'react-youtube';

import { Container, SectionSeparator } from './styles';

const Home = () => {
  const opts = {
    width: '100%'
    // height: '50%'
  };

  return (
    <Container>
      <SectionSeparator />
      <h1 className="presentational-video-title">Vídeo</h1>
      <div className="presentational-video-media">
        <YouTube videoId="SMAq7cFZczg" opts={opts} className="video" />
      </div>
    </Container>
  );
};

export default Home;
