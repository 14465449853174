import styled from 'styled-components';
import LineSeparator from '../../../../components/LineSeparator';

import AvatarCartoon from '../../../../assets/images/avatar-cartoon.png';

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-areas:
    '. reviews-separator .'
    'reviews-title reviews-title reviews-title'
    'reviews-content reviews-content reviews-content';
  grid-row-gap: 32px;
`;

export const SectionSeparator = styled(LineSeparator)`
  grid-area: reviews-separator;
  justify-self: center;
`;

export const SectionTitle = styled.h1`
  grid-area: reviews-title;
  text-align: center;
  color: ${props => props.theme.colors.darkBlue};
`;

export const Content = styled.div`
  grid-area: reviews-content;
  display: grid;
  padding: 0 16px;
  grid-template-columns: repeat(5, auto);
  grid-template-rows: 80px 40px 60px auto repeat(3, auto) auto;

  @media screen and (min-width: 640px) {
    grid-template-columns: repeat(5, minmax(5%, 10%)) 32px 48px 48px repeat(
        1,
        minmax(1vh, 10vw)
      );
    grid-template-rows: 80px 40px 60px auto repeat(3, auto);
  }
`;

export const Title = styled.h2`
  grid-column: 1 / 6;
  grid-row: 8;
  align-self: end;
  color: ${props => props.theme.colors.darkBlue};
  padding: 0.5em;

  @media screen and (min-width: 640px) {
    grid-column: 7 / 11;
    grid-row: 1 / 3;
  }
`;

export const QuotationMarkContainer = styled.div`
  grid-column: 1 / 6;
  grid-row: 9;
  align-self: center;
  color: ${props => props.theme.colors.darkBlue};
  padding: 0.5em;

  @media screen and (min-width: 640px) {
    grid-column: 7 / 11;
    grid-row: 3;
  }
`;

export const Description = styled.p`
  grid-column: 1 / 6;
  grid-row: 10;
  color: ${props => props.theme.colors.lightBlue};
  padding: 0.5em;

  @media screen and (min-width: 640px) {
    grid-column: 7 / 11;
    grid-row: 4;
  }
`;

export const Author = styled.h3`
  grid-column: 1 / 6;
  grid-row: 11;
  color: ${props => props.theme.colors.darkBlue};
  padding: 0.5em;
  align-self: end;

  @media screen and (min-width: 640px) {
    grid-column: 7 / 11;
    grid-row: 5;
  }
`;

export const AuthorOccupation = styled.p`
  grid-column: 1 / 6;
  grid-row: 12;
  align-self: start;
  color: ${props => props.theme.colors.darkBlue};
  padding: 0.5em;

  @media screen and (min-width: 640px) {
    grid-column: 7 / 11;
    grid-row: 6;
  }
`;

export const LeftArrowContainer = styled.p`
  grid-column: 1;
  grid-row: 13;
  justify-self: center;
  padding: 0.5em;
  cursor: pointer;

  @media screen and (min-width: 640px) {
    grid-column: 7;
    grid-row: 7;
    align-self: start;
  }
`;

export const RightArrowContainer = styled.p`
  grid-column: 2;
  grid-row: 13;
  align-self: start;
  padding: 0.5em;
  cursor: pointer;

  @media screen and (min-width: 640px) {
    grid-column: 8;
    grid-row: 7;
  }
`;

export const Circle = styled.div`
  display: block;
  position: relative;
  height: auto;
  background-image: ${props =>
    props.avatarImage ? `url(${props.avatarImage})` : `url(${AvatarCartoon})`};
  background-size: cover;

  img {
    width: 100%;
    height: 100%;
  }
`;

export const CurrentAvatar = styled(Circle)`
  /* background-color: purple; */
  border-radius: 50%;
  width: 80%;
  /* height: 100%; */
  padding-top: 80%;
  grid-column: 2 / 5;
  grid-row: 3 / 5;
  justify-self: end;
  align-self: end;
`;

export const TopLeftAvatar = styled(Circle)`
  /* background-color: green; */
  border-radius: 50%;
  width: 50%;
  padding-top: 50%;
  grid-column: 2;
  grid-row: 1;
  align-self: end;
`;

export const TopRightAvatar = styled(Circle)`
  /* background-color: yellow; */
  border-radius: 50%;
  width: 50%;
  padding-bottom: 50%;
  grid-column: 4;
  grid-row: 1;
  align-self: start;
`;

export const LeftTopAvatar = styled(Circle)`
  /* background-color: blue; */
  border-radius: 50%;
  width: 100%;
  padding-top: 100%;
  grid-column: 1;
  grid-row: 3 / 4;
  align-self: center;
`;

export const LeftBottomAvatar = styled(Circle)`
  /* background-color: maroon; */
  border-radius: 50%;
  width: 50%;
  padding-top: 50%;
  grid-column: 1;
  grid-row: 5;
  justify-self: center;
  align-self: center;
`;

export const BottomLeftAvatar = styled(Circle)`
  /* background-color: greenyellow; */
  border-radius: 50%;
  width: 50%;
  padding-top: 50%;
  grid-column: 3;
  grid-row: 7;
  align-self: start;
`;

export const BottomRightAvatar = styled(Circle)`
  /* background-color: red; */
  border-radius: 50%;
  width: 60%;
  padding-top: 60%;
  grid-column: 5;
  grid-row: 7;
  justify-self: start;
  align-self: start;
`;
