import styled from 'styled-components';

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr minmax(min-content, 960px) 1fr;
  grid-template-areas: '. container .';
`;

export const Container = styled.div`
  height: 100%;
  background-color: white;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: min-content;
  gap: 24px;
  grid-template-areas:
    'header header'
    'content content'
    'footer footer';
  grid-area: container;

  @media screen and (max-height: 460px) {
    grid-template-rows: 60px;
  }
`;

export const Content = styled.div`
  grid-area: content;
`;

export const HeaderBackground = styled.div`
  grid-column: 2 / 3;
  grid-row: 1/2;
  border-radius: 50%;
  width: 100%;
  height: auto;
  padding-top: 100%;
  background: ${props => props.theme.colors.orange};
`;
