import React from 'react';

import { Container } from './styles';
import FleetOwnerIntro from './components/FleetOwnerIntro';
import FleetOwnerHowTo from './components/FleetOwnerHowTo';

const FleetOwner = () => (
  <Container>
    <FleetOwnerIntro />
    <FleetOwnerHowTo />
  </Container>
);

export default FleetOwner;
