import styled from 'styled-components';

export const Image = styled.img`
  grid-area: intro-image;
  align-self: center;
  height: 100%;

  @media screen and (min-width: 375px) {
    width: 100%;
  }
`;

export const Title = styled.h1`
  grid-area: intro-title;
  align-self: end;
`;

export const Description = styled.div`
  grid-area: intro-description;

  p {
    margin-bottom: 1em;
  }
`;
