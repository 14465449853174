import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-area: download-app-section;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 16px;
  grid-template-areas: 'download-app-info download-app-mock';
  grid-template-rows: min-content;
  padding: 0 16px;

  @media screen and (max-width: 690px) {
    grid-template-columns: 1fr;
    grid-template-rows: minmax(50px, 250px) max-content;
    grid-template-areas:
      'download-app-mock'
      'download-app-info';
    grid-row-gap: 8px;
  }

  @media screen and (max-height: 640px) and (max-width: 690px) {
    height: calc(100vh - (130px));
  }

  div.download-app-mock {
    grid-area: download-app-mock;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      max-width: 60%;
      /* height: 80%; */

      @media screen and (max-width: 690px) {
        height: 100%;
      }
    }
  }

  div.download-app-info {
    grid-area: download-app-info;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      'info-title info-title'
      'info-description info-description'
      'info-apple-store info-play-store';
    gap: 16px 16px;
    justify-self: center;
    align-self: center;
    margin: 0 10%;

    @media screen and (max-width: 956px) {
      grid-template-columns: 1fr;
      grid-template-areas:
        'info-title'
        'info-description'
        'info-apple-store'
        'info-play-store';
    }

    @media screen and (max-width: 640px) {
      margin: 0;
      grid-template-columns: 1fr 1fr;
      grid-template-areas:
        'info-title info-title'
        'info-description info-description'
        'info-apple-store info-play-store';
    }

    @media screen and (max-width: 374px) {
      grid-template-columns: 1fr;
      grid-template-areas:
        'info-title'
        'info-description'
        'info-apple-store'
        'info-play-store';
    }

    h1.app-info-title {
      grid-area: info-title;
      font-size: 1.3rem;
      color: ${props => props.theme.colors.darkBlue};

      @media screen and (max-width: 640px) {
        text-align: center;
      }

      @media screen and (max-height: 460px) {
        font-size: 1rem;
      }
    }

    p.app-info-description {
      grid-area: info-description;
      font-size: 0.8rem;

      @media screen and (max-height: 460px) {
        font-size: 0.7rem;
      }
    }
  }
`;
