import styled from 'styled-components';

export const Container = styled.footer`
  grid-area: footer;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas:
    'footer-logo'
    'footer-description'
    'footer-contact-title'
    'footer-contact'
    'footer-quick-links-title'
    'footer-quick-links'
    'footer-separator'
    'footer-copyright';
  grid-row-gap: 16px;
  grid-column-gap: 16px;
  background-color: #dadada;
  padding: 16px 32px;

  @media screen and (min-width: 690px) {
    grid-template-columns: repeat(3, 1fr);
    grid-template-areas:
      'footer-logo footer-contact-title footer-quick-links-title'
      'footer-description footer-contact footer-quick-links'
      'footer-separator footer-separator footer-separator'
      'footer-copyright footer-copyright footer-copyright';

  }

  div {
    &.footer-description {
      grid-area: footer-description;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;

      p.footer-description-text {
        text-align: center;
        margin-top: 16px;
      }
    }

    &.footer-copyright {
      grid-area: footer-copyright;
      text-align: center
      color: #757575;
    }
  }
`;

export const LogoImage = styled.img`
  grid-area: footer-logo;
  justify-self: center;
`;

export const ItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const TitleQuickLinks = styled.h3`
  grid-area: footer-quick-links-title;
  justify-self: center;
  align-self: end;
`;

export const QuickLinksContainer = styled(ItemsContainer)`
  grid-area: footer-quick-links;
  h3 {
    color: ${props => props.theme.colors.darkBlue};
  }

  a {
    text-decoration: none;
    margin-top: 16px;
  }
`;

export const TitleContact = styled.h3`
  grid-area: footer-contact-title;
  justify-self: center;
  align-self: end;
`;

export const ContactContainer = styled(ItemsContainer)`
  grid-area: footer-contact;

  h3 {
    color: ${props => props.theme.colors.darkBlue};
    text-align: center;
  }

  p.contact-info {
    margin-top: 16px;
    width: 100%;

    span.contact-info-title {
      color: ${props => props.theme.colors.darkBlue};
    }

    span.contact-info-description {
      color: #757575;
    }
  }
`;

export const FooterSeparator = styled.hr`
  grid-area: footer-separator;
  border-top: 2px solid rgba(189, 189, 189, 0.3);
  margin: 0 8px;
`;
