import styled from 'styled-components';
import LineSeparator from '../../../../components/LineSeparator';

export const Container = styled.div`
  grid-area: how-to-section;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-areas:
    '. . how-to-separator how-to-separator . .'
    'how-to-mock how-to-mock how-to-mock how-to-info how-to-info how-to-info';
  grid-row-gap: 32px;
  padding: 0 16px;

  div {
    &.how-to-mock {
      grid-area: how-to-mock;
      justify-self: center;
    }

    &.how-to-info {
      grid-area: how-to-info;
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      .how-to-info-title {
        color: ${props => props.theme.colors.darkBlue};
        font-size: 1.5rem;
      }
    }
  }

  @media screen and (max-width: 640px) {
    grid-template-columns: 3fr;
    grid-template-areas:
      '. how-to-separator .'
      'how-to-mock how-to-mock how-to-mock'
      'how-to-info how-to-info how-to-info';

    div.how-to-info {
      display: grid;
      grid-row-gap: 32px;

      h1.how-to-info-title {
        text-align: center;
      }
    }
  }
`;

export const SectionSeparator = styled(LineSeparator)`
  grid-area: how-to-separator;
  justify-self: center;
`;
