import styled from 'styled-components';
import LineSeparator from '../../../../components/LineSeparator';

export const Container = styled.div`
  grid-area: how-to-info;
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 16px;
`;

export const Title = styled.h1`
  grid-area: how-to-title;
  align-self: end;
`;

export const SectionSeparator = styled(LineSeparator)`
  grid-area: how-to-separator;
  width: 100%;
`;
