import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  div.feature-icon {
    svg * {
      fill: url(#lgrad);
    }
  }

  h2.feature-title {
    font-size: 1.3rem;
    color: ${props => props.theme.colors.darkBlue};
    font-weight: 500;
    margin-top: 16px;
    text-align: center;
  }

  p.feature-description {
    font-size: 0.9rem;
    margin-top: 16px;
    text-align: center;
  }
`;
