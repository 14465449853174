import React, { Fragment } from 'react';
import { isIOS, isAndroid } from 'react-device-detect';
import { faApple, faGooglePlay } from '@fortawesome/free-brands-svg-icons';

import { Container } from './styles';
import DownloadButton from './components/DownloadButton';
import iPhoneMock from '../../../../assets/images/first-view-mockup.png';

const DownloadApp = () => {
  const renderAppleStoreButton = () => {
    return (
      <DownloadButton
        icon={faApple}
        topText="Disponível na"
        bottomText="APPLE STORE"
        className={isIOS ? 'button-highlight' : 'button'}
        href="https://itunes.apple.com/br/app/faz-rotativo-digital-bh/id1394646230"
      />
    );
  };

  const renderPlayStoreButton = () => {
    return (
      <DownloadButton
        icon={faGooglePlay}
        topText="Disponível na"
        bottomText="PLAY STORE"
        className={isAndroid ? 'button-highlight' : 'button'}
        href="https://play.google.com/store/apps/details?id=com.rotativo.digital.faixa.azul.bh.belo.horizonte.bhtrans.estacionamento"
      />
    );
  };

  return (
    <Container>
      <div className="download-app-info">
        <h1 className="app-info-title">
          A evolução em estacionamento rotativo
        </h1>
        <p className="app-info-description">
          Com o app Faz Digital BH você tem comodidade e agilidade na hora de
          estacionar seu veículo nas ruas de Belo Horizonte.
        </p>
        {isAndroid ? (
          <Fragment>
            {renderPlayStoreButton()}
            {renderAppleStoreButton()}
          </Fragment>
        ) : (
          <Fragment>
            {renderAppleStoreButton()}
            {renderPlayStoreButton()}
          </Fragment>
        )}
      </div>

      <div className="download-app-mock">
        <img src={iPhoneMock} alt="iPhone mockup" />
      </div>
    </Container>
  );
};

export default DownloadApp;
