import React from 'react';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter } from 'react-router-dom';

import GlobalStyles from './styles/global';
import theme from './styles/theme';
import { Container, Content, Wrapper } from './styles/components';

import Header from './components/Header';
import Footer from './components/Footer';
import Routes from './routes';

const App = () => (
  <>
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <Wrapper>
          <Container>
            <GlobalStyles />
            <Header />
            <Content>
              <Routes />
            </Content>
            <Footer />
          </Container>
        </Wrapper>
      </ThemeProvider>
    </BrowserRouter>
  </>
);

export default App;
