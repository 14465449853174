import styled from 'styled-components';
import LineSeparator from '../../../../components/LineSeparator';
import Feature from './components/Feature';

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-row-gap: 32px;
  grid-column-gap: 48px;
  justify-items: center;
  grid-template-areas:
    '. features-separator .'
    'features-title features-title features-title'
    'feature-secure feature-easy-to-use feature-fair-price';
  padding: 0 16px;

  h1.features-title {
    font-size: 1.5rem;
    color: ${props => props.theme.colors.darkBlue};
    grid-area: features-title;
    text-align: center;
  }

  @media screen and (max-width: 640px) {
    grid-template-columns: 3fr;
    grid-template-areas:
      '. features-separator .'
      'features-title features-title features-title'
      'feature-secure feature-secure feature-secure'
      'feature-easy-to-use feature-easy-to-use feature-easy-to-use'
      'feature-fair-price feature-fair-price feature-fair-price';
  }
`;

export const SectionSeparator = styled(LineSeparator)`
  grid-area: features-separator;
  justify-self: center;
`;

export const FeatureSecure = styled(Feature)`
  grid-area: feature-secure;
`;

export const FeatureEasyToUse = styled(Feature)`
  grid-area: feature-easy-to-use;
`;

export const FeatureFairPrice = styled(Feature)`
  grid-area: feature-fair-price;
`;
