import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Container } from './styles';
const Step = ({ icon, title, description, link, ...rest }) => (
  <Container {...rest}>
    <div className="step-icon">
      <FontAwesomeIcon icon={icon} size="2x" />
    </div>
    <h4 className="step-title">{title}</h4>
    <p className="step-description">
      {description} {link ? <a href={link}>clicando aqui.</a> : ''}
    </p>
  </Container>
);

export default Step;
