import styled from 'styled-components';

export const Container = styled.div`
  height: 4px;
  width: 100%;
  background: linear-gradient(
    to right,
    #ff6600 0%,
    ${props => props.theme.colors.orange} 100%
  );
`;
